import React from 'react'

// import GoogleAdVertical from '../googleAds/vertical/GoogleAdVertical';
import { Helmet } from 'react-helmet';

const Home = () => {

    return (
        <div className="home_container pt-16">
          <Helmet>
            <title>DIUWIN</title>
            <meta name="description" content="Join DIUWIN, the premier online casino in India. Register now and enjoy thrilling games, secure casino, and a vibrant community." />
            <meta name="keywords" content="online casino, game, DIUWIN, register, login, sports wagering, slot machines, online gaming platform, thrilling online games, Indian gaming community, secure gaming site, gaming registration, game promotions, daily gaming rewards, virtual slot machines, live gaming experience, interactive online games, entertainment hub, skill-based games, real-time multiplayer games, gaming strategies, game enthusiasts network, gaming loyalty program, top-rated online games, adventure games online, mobile-friendly games, exclusive game offers" />
            <link rel="canonical" href="https://diuwin.org/" />
          </Helmet>
    
          <div className="home_body relative flex flex-col md:flex-row">
            {/* <div className='google_ads flex-grow'>
              <GoogleAdVertical adSlot={"9439522385"} />
            </div> */}
             <a href="http://t.me/DiuWin_Helpbot" className='fixed bottom-10 right-5 md:right-10 md:bottom-10 rounded-full' title="customer service"> 
              <img src="/assets/images/diuwinChatBot.png" alt="diuwin bot" className='w-[50px] h-[50px] md:w-[70px] md:h-[70px]' />
             </a>
    
            <div className='home_body_content flex flex-col items-center gap-4 p-4 flex-1 h-[80vh]'>
              <div className="heading text-white text-center text-[15px] sm:text-2xl  font-extrabold flex flex-col items-center gap-2 p-4 border border-yellow-400 rounded-lg shadow-light-green">
                <p className=''> 
                  The Ultimate Gaming Destination
                </p>
              </div>
              <div className='home_login-btns flex flex-col-reverse items-center gap-4 p-4 sm:flex-row'>
                <a href={"https://diuwin.org/#/register?invitationCode=58224315864"} className='btn-register'>
                  Register
                </a>
                <a href='https://t.me/+HY_Pi1luvvM1MDA1' className='JoinTele' >
                  {/* Join Telegram Channel */}
                </a>
              </div>
              <div className='hero_image flex justify-center'>
                <img src='/assets/images/diuwin_hero.jpg' alt='DIUWIN' className='w-96 max-h-80 min-w-64 object-cover rounded-lg border-[2px] border-yellow-400 shadow-light-green' />
              </div>
           
                <a href="https://t.me/diuwinbigwin_111win" className='btn-bonus mt-10'>Bonus and Salary</a>
    
    
            </div>
            {/* <div className='google_ads flex-grow'>
              <GoogleAdVertical adSlot={"9439522385"} />
            </div> */}
          </div>
    
          <div className='recharge_container section_container mt-8'>
            <h1 className='text-center  text-[25px] sm:text-4xl font-bold text-white border-t-[5px] border-b-[5px] border-yellow-400 py-4'>
              How To Recharge In <span className="text-yellow-500">DIUWIN</span>
            </h1>
            <div className='flex flex-wrap justify-center gap-12 mt-8 '>
              <img src="/assets/images/recharge1.jpg" alt="DIUWIN recharge page" className='h-[500px]' />
              <img src="/assets/images/recharge2.jpg" alt="DIUWIN recharge page" className='h-[500px]' />
              <img src="/assets/images/recharge3.jpg" alt="DIUWIN recharge page" className='h-[500px]' />
            </div>
          </div>
    
          <div className='about_container section_container mt-12 p-10'>
            <h1 className='text-start text-[25px] sm:text-4xl font-bold text-white border-t border-b border-yellow-400 py-4'>
              About DIUWIN
            </h1>
            <div className='about_content flex flex-col gap-8 mt-12 text-white text-[18px] md:text-[25px] leading-relaxed md:flex-row'>
              <div>
                <p>
                  Renowned as a leading casino in India, DIUWIN prioritizes delivering an enjoyable and equitable gaming environment. Our commitment to ensuring a secure and rule-abiding gaming experience sets us apart. The cornerstone of our uniqueness lies in our vibrant community, where players can connect, exchange strategies, and collectively relish our gaming offerings. We continuously infuse fresh elements and games to maintain an exhilarating atmosphere. Furthermore, our approachable support team is readily available to assist you with just a simple click. Embark on an exceptional gaming journey with us!
                </p>
              </div>
              <div className='about_content_image flex-shrink-0 flex justify-center'>
                <img src="/assets/images/mygames.jpg" alt="DIUWIN home page" className='h-[600px] w-[400px] object-contain' />
              </div>
            </div>
          </div>
    
          <div className='register_container section_container mt-12 p-10'>
            <h1 className='text-center text-[25px] sm:text-4xl font-bold text-white border-t border-b border-yellow-400 py-4 '>
              How To Register in DIUWIN
            </h1>
            <div className='register_content flex flex-col gap-12 mt-12  md:flex-row'>
              <div className='flex-shrink-0 flex justify-center'>
                <img src='/assets/images/login.jpg' alt="Dhag365 register page" className='h-[600px] object-contain ' />
              </div>
              <div className='register_steps'>
                <ul className='flex flex-col gap-4 text-white text-[20px] md:text-[25px]'>
                  <li><p><span className='font-bold text-yellow-400'>Access:</span> Visit our website, opt for the mobile site, or download the BDG game app compatible with Android devices.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Initiate Registration:</span> Locate and select the "Register" option.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Phone Number:</span> Provide your mobile number, beginning with +91.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Security:</span> Create and verify your chosen password.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Referral:</span> Input any referral code you have.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Consent:</span> Mark the checkbox to agree to the Privacy Policy.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Finish:</span> Confirm your sign-up by clicking on "Register".</p></li>
                </ul>
              </div>
            </div>
          </div>
    
          <div className='ourGames_container section_container mt-12 p-10'>
            <h1 className='text-center text-[25px] sm:text-4xl font-bold text-white border-t border-b border-yellow-400 py-4'>
              Our Games
            </h1>
            <div className='ourGames_content flex flex-col gap-12 mt-12 md:flex-row '>
              <div className='ourGames_image flex justify-center flex-shrink-0'>
                <img src='/assets/images/mygames.jpg' alt="DIUWIN home page" className='h-[600px] object-contain'/>
              </div>
              <div className='ourGames_list'>
                <ul className='flex flex-col gap-4 text-white text-[20px] md:text-[25px]'>
                  <li><p><span className='font-bold text-yellow-400'>Lottery:</span> Test your fortune in our thrilling lottery, where predicting numbers and colors could lead to rewards.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Slot Machines:</span> Discover our assortment of slot games, effortless to engage in with abundant opportunities for substantial wins.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Sports Wagering:</span> Experience the ultimate blend of sports betting and casino excitement.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Casino Wagering:</span> Compete and triumph in our casino offerings, where strategic thinking and luck merge.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Card Gaming:</span> Our PVC game is a haven for card game enthusiasts, offering both enjoyment and challenge.</p></li>
                  <li><p><span className='font-bold text-yellow-400'>Fishing Games:</span> Engage in our innovative fishing games, blending entertainment with rewarding potential.</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
}

export default Home