import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className="mt-auto">
      {/* Disclaimer */}
      <div className="px-8 py-6 bg-white">
        <h1 className="text-3xl font-extrabold mb-5">Disclaimer:</h1>
        <p className="mb-5">
          This website is intended solely for informational purposes and does
          not encourage participation in the game. It does not make any
          guarantees regarding the accuracy or reliability of the provided
          information. Users are advised to engage in the game at their own
          discretion and risk.
        </p>
        <p className="text-lg font-semibold">
          The site contains hyperlinks to external websites. While we aim to
          offer high-quality and useful links, we have no control over the
          content or information on these external sites. Additionally, the
          content on these sites may change without notice, possibly before we
          can remove the link. This website (diuwin.com) is not
          responsible for any damage or loss resulting from actions taken based
          on the information provided here.
        </p>
        <div className="text-center text-lg">
          <a href="/privacy-policy" className="text-blue-500 hover:underline">
             Privacy Policy
          </a>
        </div>
      </div>

      {/* Copyright */}
      <div className="flex justify-center items-center py-5 bg-black text-white">
        <p>&copy; Copyright DIUWIN. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
