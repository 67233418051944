import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}/> 
      <Route path="/contact" element={<Contact/>}/> 
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
