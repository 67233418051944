import React from 'react'
import { Link, useLocation } from 'react-router-dom'


const Header = () => {

const path = useLocation().pathname;


return (
  <div className="w-full fixed top-0 left-0 bg-[#0b0c25] py-[10px] px-[20px] z-50">
    <div className="flex w-full justify-between items-center sm:w-1/2">
      <div className="flex gap-10">
        <a href="/">
          <div className={`text-white font-semibold pb-1  cursor-pointer ${path === '/' ? 'text-[#D4AF36] border-b-[2px] border-[#D4AF36]' : ''}`}>
            Home
          </div>
        </a>
        <a href="/contact">
          <div className={`text-white font-semibold pb-1 cursor-pointer ${path === '/contact' ? 'text-[#D4AF36] border-b-2 border-[#D4AF36]' : ''}`}>
            Contact
          </div>
        </a>
      </div>
      <a href="/" >
        <div className="mr-0 sm:mr-[-100px]"> 
          <img width={100} height={100} src="/assets/images/logo-primary3.png" alt="DIUWIN" className="h-12 object-contain w-[100px] sm:w-[150px]" />
        </div>
      </a>
    </div>
  </div>
);
}

export default Header