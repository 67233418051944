

import { useState } from "react";
import { Link } from "react-router-dom";


const PrivacyPolicy = () => {
  const [showContent, setShowContent] = useState(false);

  const handleToggleContent = () => {
    setShowContent((prev) => !prev);
  };

  return (
    <div className="pt-16 bg-gray-100">
      <div className="max-w-3xl mx-auto p-5 bg-white shadow-md mt-5 mb-1">
        <h1 className="text-3xl text-center text-gray-800 mb-5">
          Privacy Policy
        </h1>

        <div className="mb-5 bg-gray-50 p-3">
          <div className="flex items-center gap-2">
            <h2 className="text-xl">Contents</h2>
            <button
              onClick={handleToggleContent}
              className="text-blue-500 hover:underline"
            >
              {showContent ? "Hide" : "Show"}
            </button>
          </div>

          <ul
            className={`list-disc pl-5 transition-max-height duration-500 ease-in-out overflow-hidden list-none ${
              showContent ? "max-h-screen" : "max-h-0"
            }`}
          >
            <li>
              <a
                href="#who-we-are"
                className="text-blue-500 hover:underline"
              >
                1 Who we are
              </a>
            </li>
            <li>
              <a href="#comments" className="text-blue-500 hover:underline">
                2 Comments
              </a>
            </li>
            <li>
              <a href="#media" className="text-blue-500 hover:underline">
                3 Media
              </a>
            </li>
            <li>
              <a href="#cookies" className="text-blue-500 hover:underline">
                4 Cookies
              </a>
            </li>
            <li>
              <a
                href="#embedded-content"
                className="text-blue-500 hover:underline"
              >
                5 Embedded content from other websites
              </a>
            </li>
            <li>
              <a
                href="#who-we-share-your-data-with"
                className="text-blue-500 hover:underline"
              >
                6 Who we share your data with
              </a>
            </li>
            <li>
              <a
                href="#how-long-we-retain-your-data"
                className="text-blue-500 hover:underline"
              >
                7 How long we retain your data
              </a>
            </li>
            <li>
              <a
                href="#what-rights-you-have-over-your-data"
                className="text-blue-500 hover:underline"
              >
                8 What rights you have over your data
              </a>
            </li>
            <li>
              <a
                href="#where-your-data-is-sent"
                className="text-blue-500 hover:underline"
              >
                9 Where your data is sent
              </a>
            </li>
          </ul>
        </div>

        <section id="who-we-are" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">Who we are</h2>
          <p className="text-gray-600">
            Our website address is:{" "}
            <a href="https://diuwin.com/" className="text-blue-500 hover:underline">
                https://diuwin.com
            </a>
            . On this website, you will get details about the DIUWIN app.
          </p>
        </section>

        <section id="comments" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">Comments</h2>
          <p className="text-gray-600">
            When visitors leave comments on the site we collect the data shown
            in the comments form, and also the visitor’s IP address and browser
            user agent string to help spam detection.
          </p>
          <p className="text-gray-600">
            An anonymized string created from your email address (also called a
            hash) may be provided to the Gravatar service to see if you are
            using it. The Gravatar service privacy policy is available here:{" "}
            <a href="https://automattic.com/privacy/" className="text-blue-500 hover:underline">
                https://automattic.com/privacy/
            </a>
            . After approval of your comment, your profile picture is visible to
            the public in the context of your comment.
          </p>
        </section>

        <section id="media" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">Media</h2>
          <p className="text-gray-600">
            If you upload images to the website, you should avoid uploading
            images with embedded location data (EXIF GPS) included. Visitors to
            the website can download and extract any location data from images
            on the website.
          </p>
        </section>

        <section id="cookies" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">Cookies</h2>
          <p className="text-gray-600">
            If you leave a comment on our site you may opt-in to saving your
            name, email address, and website in cookies. These are for your
            convenience so that you do not have to fill in your details again
            when you leave another comment. These cookies will last for one
            year.
          </p>
          <p className="text-gray-600">
            If you visit our login page, we will set a temporary cookie to
            determine if your browser accepts cookies. This cookie contains no
            personal data and is discarded when you close your browser.
          </p>
          <p className="text-gray-600">
            When you log in, we will also set up several cookies to save your
            login information and your screen display choices. Login cookies
            last for two days, and screen options cookies last for a year. If
            you select “Remember Me”, your login will persist for two weeks. If
            you log out of your account, the login cookies will be removed.
          </p>
          <p className="text-gray-600">
            If you edit or publish an article, an additional cookie will be
            saved in your browser. This cookie includes no personal data and
            simply indicates the post ID of the article you just edited. It
            expires after 1 day.
          </p>
        </section>

        <section id="embedded-content" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">
            Embedded content from other websites
          </h2>
          <p className="text-gray-600">
            Articles on this site may include embedded content (e.g. videos,
            images, articles, etc.). Embedded content from other websites
            behaves in the exact same way as if the visitor has visited the
            other website.
          </p>
          <p className="text-gray-600">
            These websites may collect data about you, use cookies, embed
            additional third-party tracking, and monitor your interaction with
            that embedded content, including tracking your interaction with the
            embedded content if you have an account and are logged in to that
            website.
          </p>
        </section>

        <section id="who-we-share-your-data-with" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">
            Who we share your data with
          </h2>
          <p className="text-gray-600">
            If you request a password reset, your IP address will be included in
            the reset email.
          </p>
        </section>

        <section id="how-long-we-retain-your-data" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">
            How long we retain your data
          </h2>
          <p className="text-gray-600">
            If you leave a comment, the comment and its metadata are retained
            indefinitely. This is so we can recognize and approve any follow-up
            comments automatically instead of holding them in a moderation
            queue.
          </p>
          <p className="text-gray-600">
            For users that register on our website (if any), we also store the
            personal information they provide in their user profile. All users
            can see, edit, or delete their personal information at any time
            (except they cannot change their username). Website administrators
            can also see and edit that information.
          </p>
        </section>

        <section id="what-rights-you-have-over-your-data" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">
            What rights you have over your data
          </h2>
          <p>
            If you have an account on this site, or have left comments, you can
            request to receive an exported file of the personal data we hold
            about you, including any data you have provided to us. You can also
            request that we erase any personal data we hold about you. This does
            not include any data we are obliged to keep for administrative,
            legal, or security purposes.
          </p>
        </section>

        <section id="where-your-data-is-sent" className="mb-5">
          <h2 className="text-2xl text-gray-700 mb-2">
            Where your data is sent
          </h2>
          <p className="text-gray-600">
            Visitor comments may be checked through an automated spam detection
            service.
          </p>
        </section>

        <footer className="mt-5 text-center text-gray-600">
          <p>
            Check out our{" "}
            <a href="/contact" className="text-blue-500 hover:underline">
              Contact us
            </a>{" "}
            page if you want to know anything else.
          </p>
          {/* <p>Check out our <Link href="/disclaimer"><a className="text-blue-500 hover:underline">Disclaimer</a></Link> page and <Link href="/about"><a className="text-blue-500 hover:underline">About Us</a></Link> page to get more information.</p> */}
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
