import React from 'react'
import { Link} from 'react-router-dom'

const Contact = () => {
  return (
    <div className="pt-16 bg-white">
      <div className="p-5 pt-16 flex flex-col md:flex-row justify-between border-b border-black pb-8">  
        <div className="flex flex-1 justify-center items-center">
          <img
            src="/assets/images/Diu-Win.webp"
            alt="DIUWIN"
            width={300}
            height={300}
            className="bg-gray-100 p-4 rounded-xl border-4 border-[#2fc73c]"
          />
        </div>
        <div className="flex flex-1 flex-col items-center gap-5 mt-8 md:mt-0">
          <h1 className="text-center text-2xl md:text-4xl font-bold">Contact Us</h1>
          <p className="text-center text-sm md:text-base">
            Please feel free to reach out to us on our dedicated Telegram ID for
            a thorough discussion and comprehensive explanation about the
            DIUWIN. We are more than happy to provide you with detailed
            information on how to enhance your earnings through this exciting
            game. We understand the importance of maximizing your earning
            potential and are committed to guiding you towards effective
            strategies and techniques.
          </p>
          <a href="http://t.me/DiuWin_Helpbot" className='no-underline'>
              <button className="bg-indigo-600 text-white font-bold py-3 px-7 rounded-xl flex items-center gap-2 hover:bg-black transition-colors duration-200">
                Contact Us
                <div className="flex justify-center items-center">
                  <div className="w-2.5 h-0.5 bg-indigo-600 transition-all duration-200 relative">
                    <div className="absolute right-1 top-[-3px] border-r-2 border-b-2 border-white transform rotate-45 w-2 h-2"></div>
                  </div>
                </div>
              </button>
            </a>
        </div>
      </div>
    </div>
  )
}

export default Contact
